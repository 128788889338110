import React from "react";

const Footer = () => {
   React.useEffect(() => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
  }, []);
   return (
      <>
         <h1>This is Eror page</h1>
      </>
   );
};

export default Footer;
